import styled from "@emotion/styled";

const Anchor = styled.a`
  transition: ${p => p.theme.colorModeTransition};
  color: ${p => p.theme.colors.accent};
  border-bottom-color: ${p => p.theme.colors.anchor};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 2px;
}

  &:visited {
    color: ${p => p.theme.colors.accent};
    opacity: 0.85;
  }

  &:hover,
  &:focus {
  }
`;

export default Anchor;
