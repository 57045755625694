import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Blockquote = styled.blockquote`
  transition: ${p => p.theme.colorModeTransition};
  margin: 15px auto 50px;
  border-radius: 3px;
  background: rgb(241, 241, 239) none repeat scroll 0% 0%;
  padding: 16px 16px 16px 12px;
  width: 100%;
  max-width: 680px;
}

  ${mediaqueries.tablet`
    margin: 10px auto 35px;
    max-width: 486px;
  `};

  & > p {
    max-width: 880px !important;
    padding-right: 100px;
    padding-bottom: 0;
    width: 100%;
    margin: 0 auto;
    line-height: 1.32;
    color: #111216;

    ${mediaqueries.tablet`
      padding: 0 180px;
    `};

    ${mediaqueries.phablet`
      padding: 0 20px 0 40px;
    `};
  }
`;

export default Blockquote;
